import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
       
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              "\n  .hydroboots .faq__item__name { color: #77787b; }\n  .hydroboots .faq__item__content { color:#77787b; }\n  .hydroboots .faq__item__short .btn__more { background-color: #cfddf2; }\n  .hydroboots .faq__item__short .btn__less { background-color: #cfddf2; }\n"
          }}
        />
        <div className="addbg2">
          <div className="bp">
            <div className="wrapper bp">
              <header
                style={{
                  height: "120px"
                }}
              >
                <div
                  style={{
                    height: "67px"
                  }}
                >
                  <h1 className="logo">
                    <a href="/" />
                  </h1>
                  <nav>
                    <a href="/site/index.php/produkty/" className="link">
                      Produkty
                    </a>
                    <span>| </span>
                    <a
                      href="/site/index.php/o-neutrogenie/kim-jestesmy/"
                      className="link"
                    >
                      O Neutrogenie
                    </a>
                    <span>| </span>
                    <a href="/bad-piekna/" className="section">
                      Bądź piękna
                    </a>
                    <span>| </span>
                    <a
                      href="/site/index.php/see-whats-possible/"
                      className="link"
                    >
                      See What's Possible
                    </a>
                    <span>| </span>
                    <a
                      href="/site/index.php/skora-z-niedoskonalosciami/"
                      className="link"
                    >
                      Skóra z niedoskonałościami
                    </a>
                    <span>| </span>
                  </nav>
                </div>
                <div
                  style={{
                    "padding-left": "185px",
                    height: "53px"
                  }}
                >
                  <nav
                    className="second"
                    style={{
                      float: "none"
                    }}
                  >
                    <a href="/site/index.php/hydroboost/" className="link">
                      Hydro Boost
                    </a>
                    <span>| </span>
                    <a href="/site/index.php/skin-detox/" className="link">
                      Skin Detox
                    </a>
                    <span>| </span>
                    <a href="/site/index.php/cellular-boost/" className="link">
                      Cellular Boost
                    </a>
                    <span />
                  </nav>
                </div>
              </header>
              <div className="top">
                <h2>Pielęgnacja stóp</h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/bad-piekna/">Bądź piękna</a>{" "}
                  <span>&gt;</span>
                  Pielęgnacja stóp
                </div>
              </div>
            </div>
          </div>
          <div className="submenu bpmenu">
            <div className="wrapper">
              <a href="/site/index.php/bad-piekna/test-skory/" className="link">
                Test skóry
              </a>
              <a
                href="/site/index.php/bad-piekna/ogolnopolskie-badanie-skory/"
                className="link"
              >
                Badanie Skóry
              </a>
              <a
                href="/site/index.php/bad-piekna/sucha-skora/"
                className="link"
              >
                Sucha skóra
              </a>
              <a
                href="/site/index.php/bad-piekna/pielegnacja-twarzy/"
                className="link"
              >
                Pielęgnacja twarzy
              </a>
              <a
                href="/site/index.php/bad-piekna/pielegnacja-ust/"
                className="link"
              >
                Pielęgnacja ust
              </a>
              <a
                href="/site/index.php/bad-piekna/pielegnacja-stop/"
                className="current"
              >
                Pielęgnacja stóp
              </a>
              <a
                href="/site/index.php/bad-piekna/pielegnacja-ciala/"
                className="link"
              >
                Pielęgnacja ciała i regeneracja skóry
              </a>
              <a
                href="/site/index.php/bad-piekna/pielegnacja-doni/"
                className="link"
              >
                Pielęgnacja dłoni
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <a name="aboutAnchor" />
              <section className="first">
                <div className="text full">
                  <h2>Dbaj o stopy bo to one niosą Cię przez świat</h2>
                </div>
                <div className="text half left">
                  <div className="p">
                    Nadmierna suchość i pękanie skóry stóp, głównie pięt, może
                    mieć wiele przyczyn:
                    <br />
                    <ul>
                      <li>niewłaściwa pielęgnacja,</li>
                      <li>narażenie na działanie skrajnych temperatur,</li>
                      <li>zbyt częste kąpiele lub prysznice,</li>
                      <li>nieodpowiednie obuwie,</li>
                      <li>
                        dolegliwości o podłożu dziedzicznym powodujące
                        <br />
                        upośledzoną czynność gruczołów potowych,
                      </li>
                      <li>choroby - np. cukrzyca.</li>
                    </ul>
                  </div>
                </div>
                <div className="text half right">
                  <p>
                    Problem nadmiernej suchości stóp występuje przede wszystkim
                    u osób w starszym wieku, ponieważ ich skóra szybciej traci
                    naturalne nawilżenie. Ponadto osoby starsze cierpią często
                    na choroby przewlekłe, takie jak np. cukrzyca. Bez
                    odpowiedniej pielęgnacji i regeneracji skóry, nadmiernie
                    sucha skóra palców u nóg i pięt może pękać, powodując
                    dyskomfort i ból podczas chodzenia.
                    <br />
                    Suchość stóp może pojawić się również w skutek pielęgnacji
                    tej części ciała. Dlatego zadbaj skutecznie o kondycję skóry
                    stóp i zapoznaj się z rytuałem pielęgnacyjnym, który pozwoli
                    Ci cieszyć się pięknymi stopami przez cały rok!
                  </p>
                </div>
              </section>
              <section>
                <div className="bg stopy1">
                  <div className="text left">
                    <p>
                      <br />
                      <br />
                      <br />
                    </p>
                    <h2>Mycie stóp</h2>
                    <p>
                      Mycie stóp to nie tylko podstawowa zasada higieny, ale
                      także - szczególnie latem - skuteczna ochrona przed
                      opuchliznami oraz uczuciem ciężkości. Stopy należy myć w
                      letniej wodzie, a następnie dokładnie osuszyć ręcznikiem.
                      Pamiętaj, aby nie pocierać mocno ręcznikiem wilgotnych
                      stóp, szczególnie w przestrzeni międzypalcowej. To może
                      spowodować uszkodzenia, które sprzyjają rozwojowi grzybicy
                      i bakterii. Postaraj się zafundować stopom raz w tygodniu
                      kąpiel w wodzie ze specjalną solą kosmetyczną, a następnie
                      wykonaj peeling.
                    </p>
                  </div>
                </div>
              </section>
              <section>
                <div className="bg stopy2">
                  <div className="text right">
                    <h2>Peeling stóp</h2>
                    <p>
                      Peeling stóp jest równie ważny jak peeling ciała.
                      Skutecznie poprawia krążenie poprzez masaż&nbsp;i pozwala
                      usunąć martwy naskórek i zrogowacenia. Wykonywany
                      systematycznie wygładza szorstkości i nadaje stopom
                      niezwykłą gładkość, przygotowując je do prawidłowego
                      nawilżenia i natłuszczenia. Pamiętaj, że systematyczne
                      usuwanie zrogowaciałego naskórka pozwala w znacznym
                      stopniu zapobiegać odciskom.
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </section>
              <section className="last">
                <div className="bg stopy3">
                  <div className="text left">
                    <h2>
                      Nawilżanie
                      <br />i natłuszczanie stóp
                    </h2>
                    <p>
                      Nawilżanie i natłuszczanie stóp to podstawowy zabieg
                      pielęgnacyjny, którego zadaniem jest skuteczne odżywianie
                      i regeneracja naskórka. Pamiętając o codziennym nawilżaniu
                      stóp, zapobiegniesz powstawaniu zgrubień, zrogowaceń oraz
                      przebarwień. Krem do suchych stóp z Formułą Norweską
                      szybko poprawia nawilżenie skóry, jednocześnie inicjując
                      proces regeneracji bariery naskórkowej. Sięgnij także po
                      pozostałe specjalistyczne produkty z Formułą Norweską do
                      pielęgnacji stóp. Działają one kojąco nawet na bardzo
                      mocno popękaną skórę stóp/pięt, pomagają usunąć
                      zrogowacenia i wspomagają regenerację.
                    </p>
                  </div>
                </div>
              </section>
              <div className="fb">
                <div
                  style={{
                    width: "250px",
                    height: "auto",
                    float: "left"
                  }}
                >
                  <iframe
                    src="//www.facebook.com/plugins/like.php?href=https://neutrogena.com.pl/site/index.php/index.php/bad-piekna/pielegnacja-stop/&send=false&layout=button_count&width=200&show_faces=false&font&colorscheme=light&action=like&height=21&locale=pl_PL"
                    scrolling="no"
                    frameBorder="0"
                    style={{
                      border: "none",
                      overflow: "hidden",
                      width: "200px",
                      height: "21px"
                    }}
                    allowTransparency="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/site/index.php/o-neutrogenie/kim-jestesmy/">
                  neutrogena<sup>®</sup> obecnie
                </a>
                <span>|</span>
                <a href="/site/index.php/o-neutrogenie/formula-norweska/">
                  czym jest formuła norweska?
                </a>
                <span>|</span>
                <a href="/site/index.php/o-neutrogenie/historia/">
                  poznaj naszą historię
                </a>
                <span>|</span>
                <a href="/site/index.php/produkty/">
                  zobacz produkty neutrogena
                </a>
              </div>
            </div>
          </div>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/" className="logo2" />
                <a href="/site/index.php/nota-prawna/">Nota Prawna</a>
                <a href="/site/index.php/polityka-prywatnosci/">
                  Polityka Prywatności
                </a>
                <a href="/site/index.php/cookies/">Polityka cookies</a>
                 <a href="/site/index.php/kontakt/">Zachęcamy do kontaktu</a>              
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 01/02/2023
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;
